import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';
import { DatadogLoggingService } from '@edx/frontend-logging';
import { UserCareerMenu, UserDashboardMenu, HelpChatbot } from '@edx/frontend-plugin-learner-dashboard';
import { RecommendationsPanel } from '@edx/frontend-plugin-recommendations';

const config = {
  OPTIMIZELY_FULL_STACK_SDK_KEY: 'GiXcfTudkzWv8T29KJgES',
  loggingService: DatadogLoggingService,
  pluginSlots: {
    widget_sidebar_plugin_slot: {
      keepDefault: false,
      plugins: [
        {
          // insert the recommendations panel
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'recommendations_plugin',
            type: DIRECT_PLUGIN,
            priority: 60,
            RenderWidget: RecommendationsPanel,
          },
        },
      ],
    },
    header_user_menu_group_item_slot: {
      plugins: [
        {
          // Insert career button in header menu dropdown
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'career_menu_item',
            type: DIRECT_PLUGIN,
            RenderWidget: UserCareerMenu,
          },
        },
      ],
    },
    header_user_menu_group_slot: {
      plugins: [
        {
          // insert personal and enterprise dashboard buttons in header menu dropdown
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'dashboard_user_menu_group',
            type: DIRECT_PLUGIN,
            RenderWidget: UserDashboardMenu,
          },
        },
      ],
    },
    footer_slot: {
      keepDefault: true,
      plugins: [
        {
          // insert the optimizely-experimented chatbot to transition from zendesk to five9
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'optimizely_plugin',
            type: DIRECT_PLUGIN,
            priority: 60,
            RenderWidget: HelpChatbot,
          },
        },
      ],
    }
  },
};

export default config;
